/*
import React, { Fragment } from 'react';

import {
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

const SignUpPage = () => {
  return (
    <Fragment>
      <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
        <Flex p={8} flex={1} align={'center'} justify={'center'}>
          <Stack spacing={6} w={'full'} maxW={'lg'}>
            <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
              <Text
                as={'span'}
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  height: useBreakpointValue({ base: '20%', md: '30%' }),
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                  bg: 'blue.400',
                  zIndex: -1,
                }}
              >
                Freelance
              </Text>
              <br />{' '}
              <Text color={'blue.400'} as={'span'}>
                Design Projects
              </Text>{' '}
            </Heading>
            <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
              The project board is an exclusive resource for contract work. It's
              perfect for freelancers, agencies, and moonlighters.
            </Text>
            <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
              <Button
                rounded={'full'}
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
              >
                Create Project
              </Button>
              <Button rounded={'full'}>How It Works</Button>
            </Stack>
          </Stack>
        </Flex>
        <Flex flex={1}>
          <Image
            alt={'Login Image'}
            objectFit={'cover'}
            src={
              'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
            }
          />
        </Flex>
      </Stack>
    </Fragment>
  );
};

export default SignUpPage;
*/

import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { signUpSchema } from '../validations/SignUpSchema';

// context
import { useAuth } from '../contexts/AuthContext';

import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  Icon,
  Text,
  Divider,
  useToast,
  Box,
  FormErrorMessage,
  useColorModeValue,
  Highlight,
} from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';

const SignUpPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const {
    handleGoogleSignUp,
    handleSignUp,
    authLoading,
    authUser,
    setAuthLoading,
  } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setAuthLoading(true);
      if (authUser) {
        console.log({ authUser });
        navigate('/');
      }
      setAuthLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Fragment>
      <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
        <Flex p={8} flex={1} align={'center'} justify={'center'}>
          <Stack spacing={4} w={'full'} maxW={'md'}>
            <Heading lineHeight="tall">
              <Highlight
                query="Agent"
                styles={{ px: '2', py: '1', bg: 'red.100' }}
              >
                Agent Sign Up
              </Highlight>
            </Heading>
            <Heading fontSize={'2xl'} mb="6">
              Sign up to create an account
            </Heading>
            <Button
              colorScheme="gray"
              onClick={async () => {
                const { error } = await handleGoogleSignUp();
                if (error) {
                  toast({
                    title: 'Error on Google Log In',
                    description: error,
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                    position: 'top-right',
                  });
                }
              }}
            >
              <Flex align="center">
                <Icon as={FcGoogle} boxSize={6} mr="2" />
                <Text>Sign Up with Google</Text>
              </Flex>
            </Button>
            <Flex align="center" py="3">
              <Divider borderWidth="1px" borderColor="gray.300" />
              <Text fontSize="sm" mx="4">
                or
              </Text>
              <Divider borderWidth="1px" borderColor="gray.300" />
            </Flex>
            <Formik
              initialValues={{
                email: '',
                password: '',
                confirmPassword: '',
              }}
              validationSchema={signUpSchema}
              onSubmit={async values => {
                if (values.password != values.confirmPassword) {
                  toast({
                    title: 'Error on Sign Up',
                    description:
                      'Password and Confirm Password must be the same',
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                    position: 'top-right',
                  });
                } else {
                  const { error } = await handleSignUp(
                    values.email,
                    values.password
                  );
                  if (error) {
                    console.log(error);
                    toast({
                      title: 'Error on Sign Up',
                      description: error,
                      status: 'error',
                      duration: 4000,
                      isClosable: true,
                      position: 'top-right',
                    });
                  }
                }
              }}
            >
              {formik => (
                <form onSubmit={formik.handleSubmit}>
                  <Box>
                    <FormControl
                      isInvalid={formik.errors.email && formik.touched.email}
                      mb="3"
                    >
                      <FormLabel>Email address</FormLabel>
                      <Field
                        as={Input}
                        id="email"
                        name="email"
                        type="email"
                        disabled={authLoading}
                      />
                      <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        formik.errors.password && formik.touched.password
                      }
                      mb="3"
                    >
                      <FormLabel>Password</FormLabel>
                      <Field
                        as={Input}
                        id="password"
                        name="password"
                        type="password"
                        disabled={authLoading}
                      />
                      <FormErrorMessage>
                        {formik.errors.password}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        formik.errors.confirmPassword &&
                        formik.touched.confirmPassword
                      }
                      mb="3"
                    >
                      <FormLabel>Confirm Password</FormLabel>
                      <Field
                        as={Input}
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        disabled={authLoading}
                      />
                      <FormErrorMessage>
                        {formik.errors.confirmPassword}
                      </FormErrorMessage>
                    </FormControl>

                    <Stack spacing={10} pb="5">
                      <Stack
                        direction={{ base: 'column', sm: 'row' }}
                        align={'start'}
                        justify={'space-between'}
                      ></Stack>
                      <Button type="submit" colorScheme="blue">
                        Sign Up
                      </Button>
                    </Stack>

                    <Flex>
                      <Text>
                        Already have an account?{' '}
                        <Link
                          color="blue.400"
                          onClick={() => {
                            navigate('/login');
                          }}
                        >
                          Log in instead
                        </Link>
                      </Text>
                    </Flex>
                  </Box>
                </form>
              )}
            </Formik>
          </Stack>
        </Flex>
        <Flex flex={1}>
          <Image
            onClick={() => {
              window.location.assign('https://www.utopiahomes.us/');
            }}
            style={{ cursor: 'pointer' }}
            alt={'Login Image'}
            objectFit={'cover'}
            src={'https://i.imgur.com/Twa7SXT.jpg'}
          />
        </Flex>
      </Stack>
    </Fragment>
  );
};

export default SignUpPage;
