import React, { Fragment } from 'react';
import SidebarWithHeader from '../components/SidebarWithHeader';

// components
import VerifyEmailSection from '../components/DashboardComponents/VerifyEmailSection';
import ApplicationSubmittedSection from '../components/DashboardComponents/ApplicationSubmittedSection';
import OnboardingSection from '../components/DashboardComponents/OnboardingSection';

// contexts
import { useAuth } from '../contexts/AuthContext';

const HomePage = () => {
  const { authUser } = useAuth();

  return (
    <Fragment>
      {authUser && authUser.data.applicationCompleted === true ? (
        <SidebarWithHeader>
          <ApplicationSubmittedSection />
        </SidebarWithHeader>
      ) : (
        <SidebarWithHeader>
          {authUser && authUser.emailVerified ? (
            <OnboardingSection />
          ) : (
            <VerifyEmailSection />
          )}
        </SidebarWithHeader>
      )}
    </Fragment>
  );
};

export default HomePage;
