import React, { Fragment, useEffect, useState } from 'react';
import { isEmptyString, sleep } from '../../utils/generalFunctions';

// contexts
import { Timestamp } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
// chakra
import { PhoneIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Progress,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';

const OnboardingSection = () => {
  const {
    authUser,
    updateApplicationFields,
    updateAgentFields,
    applicationData,
  } = useAuth();

  const [sectionStep, setSectionStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [stepOneFirstName, setStepOneFirstName] = useState('');
  const [stepOneLastName, setStepOneLastName] = useState('');

  const [stepOneEmail, setStepOneEmail] = useState('');
  const [stepOnePhone, setStepOnePhone] = useState('');

  const [stepTwoAgentLicenseNumber, setStepTwoAgentLicenseNumber] =
    useState('');
  const [stepTwoAgentBrokerage, setStepTwoAgentBrokerage] = useState('');
  const [stepTwoAgentOtherBrokerage, setStepTwoAgentOtherBrokerage] =
    useState('');

  const [stepThreeOperatingMetro, setStepThreeOperatingMetro] = useState('');
  const [stepThreePreferredCities, setStepThreePreferredCities] = useState('');
  const toast = useToast();

  const handleNext = async () => {
    try {
      setButtonLoading(true);
      let payload = {
        uid: authUser.uid,
      };

      let updateUser = false;

      if (sectionStep === 1) {
        payload = {
          uid: authUser.uid,
          stepOne: [
            {
              question: 'Agent First Name:',
              answer: stepOneFirstName,
            },
            {
              question: 'Agent Last Name:',
              answer: stepOneLastName,
            },
            {
              question: 'Agent Email:',
              answer: stepOneEmail,
            },
            {
              question: 'Agent Phone Number:',
              answer: stepOnePhone,
            },
          ],
          currentStep: 1,
          applicationCompleted: false,
        };
      } else if (sectionStep == 2) {
        payload = {
          stepTwo: [
            {
              question: 'Agent License Number:',
              answer: stepTwoAgentLicenseNumber,
            },
            {
              question: 'Agent Brokerage:',
              answer: stepTwoAgentBrokerage,
            },
            {
              question: 'Agent Other Brokerage:',
              answer: stepTwoAgentOtherBrokerage,
            },
          ],
          currentStep: 2,
        };
      } else if (sectionStep == 3) {
        payload = {
          stepThree: [
            {
              question: 'Agent Operating Metro:',
              answer: stepThreeOperatingMetro,
            },
            {
              question: 'Agent Preferred Cities:',
              answer: stepThreePreferredCities,
            },
          ],
          currentStep: 3,
          applicationCompleted: true,
          timeSubmitted: Timestamp.now(),
        };
        updateUser = true;
      }

      await updateApplicationFields(payload);

      if (updateUser) {
        await updateAgentFields({
          applicationCompleted: true,
          legalName: {
            firstName: stepOneFirstName,
            lastName: stepOneLastName,
          },
        });
        setButtonLoading(false);
        window.location.reload(false);
      }

      setSectionStep(sectionStep + 1);
      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
      toast({
        title: 'Error',
        description: 'Something went wrong. Please try again',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const setInitialData = async () => {
    if (applicationData) {
      if (applicationData.stepOne) {
        setStepOneFirstName(applicationData.stepOne[0]?.answer);
        setStepOneLastName(applicationData.stepOne[1]?.answer);
        setStepOneEmail(applicationData.stepOne[2]?.answer);
        setStepOnePhone(applicationData.stepOne[3]?.answer);
      }
      if (applicationData.stepTwo) {
        setStepTwoAgentLicenseNumber(applicationData.stepTwo[0]?.answer);
        setStepTwoAgentBrokerage(applicationData.stepTwo[1]?.answer);
        setStepTwoAgentOtherBrokerage(applicationData.stepTwo[2]?.answer);
      }
      if (applicationData.stepThree) {
        setStepThreeOperatingMetro(applicationData.stepThree[0]?.answer);
        setStepThreePreferredCities(applicationData.stepThree[1]?.answer);
      }
    }
  };

  useEffect(() => {
    setInitialData();
  }, [applicationData]);

  const StartApplication = (
    <Fragment>
      <Box
        w="100%"
        px={6}
        py={10}
        bg="white"
        rounded="md"
        align="center"
        textAlign="center"
      >
        <Stack spacing="8" align="center" textAlign="center">
          <Image
            boxSize="100px"
            objectFit="cover"
            src="https://i.imgur.com/O73TMKn.png"
            alt="emails"
          />
          <Box>
            <Heading as="h3" size="lg" mb="3">
              Begin Application
            </Heading>
            <Text fontSize="md" color="gray.500">
              Become a partner agent by joining Utopia Agent Network (UAN). Our
              Partner agents earn full commission, close more deals, get more
              clients from us and help those who are not mortgage ready yet
              become homeowners.
            </Text>
          </Box>
          <Button
            colorScheme="blue"
            onClick={async () => {
              setLoading(true);
              setSectionStep(1);
              await sleep(500);
              setLoading(false);
            }}
          >
            Start
          </Button>
        </Stack>
      </Box>
    </Fragment>
  );

  const UserSection = (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="green"
          rounded="full"
          size="sm"
          value={33.3333}
          bg="gray.200"
        />
        <Box w="100%" px={8} py={6} bg="white" rounded="md">
          <Stack spacing="8" mb="5">
            <Box>
              <Heading as="h3" size="lg" mb="2">
                Sign up as an Agent
              </Heading>
              <Text fontSize="md" fontWeight="600" color="grey">
                Become a partner agent by joining Utopia Agent Network (UAN).
                Our Partner agents earn full commission, close more deals, get
                more clients from us and help those who are not mortgage ready
                yet become homeowners.
              </Text>
            </Box>
            <Box>
              <Stack spacing="5">
                <FormControl>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    type="text"
                    value={stepOneFirstName}
                    onChange={event => {
                      setStepOneFirstName(event.target.value);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    type="text"
                    value={stepOneLastName}
                    onChange={event => {
                      setStepOneLastName(event.target.value);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Phone Number</FormLabel>
                  <InputGroup>
                    <InputLeftElement children={<PhoneIcon />} />
                    <Input
                      type="tel"
                      value={stepOnePhone}
                      onChange={event => {
                        setStepOnePhone(event.target.value);
                      }}
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Flex>
          <Spacer />
          <Box>
            <Button
              colorScheme="blue"
              onClick={handleNext}
              disabled={
                isEmptyString(stepOneFirstName) ||
                isEmptyString(stepOneLastName) ||
                isEmptyString(stepOnePhone)
              }
            >
              {buttonLoading ? <Spinner /> : 'Next'}
            </Button>
          </Box>
        </Flex>
      </Stack>
    </Fragment>
  );

  const AgentSection = (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="green"
          rounded="full"
          size="sm"
          value={66.6666}
          bg="gray.200"
        />
        <Box w="100%" px={8} py={6} bg="white" rounded="md">
          <Stack spacing="8" mb="5">
            <Box>
              <Heading as="h3" size="lg" mb="2">
                Agent Details
              </Heading>
              <Text fontSize="md" fontWeight="600" color="grey">
                Please provide the details about you as an agent. This includes
                things like your Agent Registration License Number and the
                Brokerage that you currently work for.
              </Text>
            </Box>
            <Box>
              <Stack spacing="5">
                <FormControl>
                  <FormLabel>Agent License Number</FormLabel>
                  <Input
                    type="text"
                    value={stepTwoAgentLicenseNumber}
                    onChange={event => {
                      setStepTwoAgentLicenseNumber(event.target.value);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Brokerage</FormLabel>
                  <Input
                    type="text"
                    value={stepTwoAgentBrokerage}
                    onChange={event => {
                      setStepTwoAgentBrokerage(event.target.value);
                    }}
                  />
                  {/* <Select
                    value={stepTwoAgentBrokerage}
                    onChange={e => {
                      setStepTwoAgentBrokerage(e.target.value);
                    }}
                  >
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="other">Other</option>
                  </Select> */}
                  <FormHelperText>
                    What Brokerage do you use as an agent
                  </FormHelperText>
                </FormControl>
                {/* {stepTwoAgentBrokerage == 'other' && (
                  <FormControl>
                    <FormLabel>Specify Brokerage</FormLabel>
                    <Input
                      type="text"
                      value={stepTwoAgentOtherBrokerage}
                      onChange={event => {
                        setStepTwoAgentOtherBrokerage(event.target.value);
                      }}
                    />
                    <FormHelperText>
                      What Brokerage do you use as an agent
                    </FormHelperText>
                  </FormControl>
                )} */}
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Flex>
          <Spacer />
          <Box>
            <Button
              mr="5"
              onClick={() => {
                setSectionStep(1);
              }}
            >
              Prev
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleNext}
              disabled={
                isEmptyString(stepTwoAgentLicenseNumber) ||
                isEmptyString(stepTwoAgentBrokerage) ||
                (stepTwoAgentBrokerage == 'other' &&
                  isEmptyString(stepTwoAgentOtherBrokerage))
              }
            >
              {buttonLoading ? <Spinner /> : 'Next'}
            </Button>
          </Box>
        </Flex>
      </Stack>
    </Fragment>
  );

  const LocationSection = (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="green"
          rounded="full"
          size="sm"
          value={99.9999}
          bg="gray.200"
        />
        <Box w="100%" px={8} py={6} bg="white" rounded="md">
          <Stack spacing="8" mb="5">
            <Box>
              <Heading as="h3" size="lg" mb="2">
                Location Details
              </Heading>
              <Text fontSize="md" fontWeight="600" color="grey">
                Please provide details about the main location(s) that you as
                the agent will be targeting for potential customers.
              </Text>
            </Box>
            <Box>
              <Stack spacing="5">
                <FormControl>
                  <FormLabel>Operating Metro</FormLabel>
                  <Input
                    type="text"
                    value={stepThreeOperatingMetro}
                    onChange={event => {
                      setStepThreeOperatingMetro(event.target.value);
                    }}
                  />
                  <FormHelperText>
                    What is the Metro Area(s) that you are currently operating
                    in? Ex. Dallas–Fort Worth metroplex
                  </FormHelperText>
                </FormControl>
                <FormControl>
                  <FormLabel>Preferred Cities</FormLabel>
                  <Textarea
                    value={stepThreePreferredCities}
                    onChange={event => {
                      setStepThreePreferredCities(event.target.value);
                    }}
                  />
                  <FormHelperText>
                    What are the specific cities you are or would like to
                    operate in?
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Flex>
          <Spacer />
          <Box>
            <Button
              mr="5"
              onClick={() => {
                setSectionStep(2);
              }}
            >
              Prev
            </Button>
            <Button
              colorScheme="green"
              onClick={handleNext}
              disabled={
                isEmptyString(stepThreeOperatingMetro) ||
                isEmptyString(stepThreePreferredCities)
              }
            >
              {buttonLoading ? <Spinner /> : 'Submit'}
            </Button>
          </Box>
        </Flex>
      </Stack>
    </Fragment>
  );

  const loadingComponent = (
    <Fragment>
      <Box
        bg="white"
        p="10"
        rounded="md"
        mb="10"
        w="100%"
        align="center"
        justify="center"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
          mb="4"
        />
        <Heading as="h5" size="sm">
          Loading
        </Heading>
      </Box>
    </Fragment>
  );

  const renderStep = index => {
    switch (index) {
      case 0:
        return StartApplication;
      case 1:
        return UserSection;
      case 2:
        return AgentSection;
      case 3:
        return LocationSection;
      default:
        return StartApplication;
    }
  };

  if (loading) {
    return (
      <Fragment>
        <Container maxW="container.sm">{loadingComponent}</Container>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Container maxW="container.sm">{renderStep(sectionStep)}</Container>
    </Fragment>
  );
};

export default OnboardingSection;
