import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './Private.routes';

// pages
import LogInPage from '../pages/LogInPage';
import SignUpPage from '../pages/SignUpPage';
import HomePage from '../pages/HomePage';
import AuthAction from '../pages/AuthAction';
import ResetPassword from '../pages/ResetPassword';
import EmailSent from '../pages/EmailSent';

const MainRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        }
      />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/login" element={<LogInPage />} />
      <Route path="/auth_action" element={<AuthAction />} />
      <Route path="/reset_password" element={<ResetPassword />} />
      <Route path="/email_sent" element={<EmailSent />} />
    </Routes>
  );
};

export default MainRoutes;
