import * as yup from 'yup'

const ResetPasswordSchema = yup.object({
  newPassword: yup
    .string()
    .min(6, 'Password must be longer that 6 character')
    .required('Required'),
  confirmNewPassword: yup
    .string()
    .min(6, 'Password must be longer that 6 character')
    .required('Required'),
})

export { ResetPasswordSchema }
