import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import {
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import app from '../utils/firebase';

import { useToast } from '@chakra-ui/react';
import { makeid, sleep } from '../utils/generalFunctions';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const toast = useToast();

  const db = getFirestore(app);

  const [authUser, setAuthUser] = useState(null);
  const [applicationData, setApplicationData] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth(app);

      await onAuthStateChanged(auth, async user => {
        setAuthLoading(true);
        if (user) {
          console.log('SIGNED IN: ', user);

          console.log({ location: location.pathname });

          const docRef = doc(db, 'agents', user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            console.log({ data: docSnap.data() });
            setAuthUser({
              accessToken: user.accessToken,
              displayName: user.displayName,
              email: user.email,
              emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              uid: user.uid,
              data: docSnap.data(),
            });

            getUserApplication(user.uid);

            if (
              location.pathname == '/signup' ||
              location.pathname == '/login'
            ) {
              navigate('/');
            }
          } else {
            await setDoc(doc(db, 'agents', auth.currentUser.uid), {
              email: auth.currentUser.email,
              applicationCompleted: false,
              qualifiedAmount: 0,
              isAdmin: false,
              user_id: makeid(7),
              account: {
                accountCreated: Timestamp.now(),
                nudge_8hr_sent: false,
                nudge_56hr_sent: false,
              },
            });

            const docSnap2 = await getDoc(docRef);

            setAuthUser({
              accessToken: user.accessToken,
              displayName: user.displayName,
              email: user.email,
              emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              uid: user.uid,
              data: docSnap2.data(),
            });

            if (
              location.pathname == '/signup' ||
              location.pathname == '/login'
            ) {
              navigate('/');
            }
          }
          setAuthLoading(false);
        } else {
          console.log('SIGNED OUT');
          setAuthUser(null);
          setAuthLoading(false);
        }
      });
    };

    fetchData().catch(console.error);
  }, []);

  const handleSignUp = async (email, password) => {
    console.log('Calling handleSignUp');
    try {
      setAuthLoading(true);
      const auth = getAuth(app);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log({ user: userCredential.user });

      await sendEmailVerification(auth.currentUser);
      console.log('Verification email sent');

      await setDoc(doc(db, 'agents', auth.currentUser.uid), {
        email: auth.currentUser.email,
        applicationCompleted: false,
        qualifiedAmount: 0,
        isAdmin: false,
        user_id: makeid(7),
        account: {
          accountCreated: Timestamp.now(),
          nudge_8hr_sent: false,
          nudge_56hr_sent: false,
        },
      });
      console.log('User entry created');

      setAuthLoading(false);

      if (userCredential.user) {
        return { error: false };
      }

      window.location.reload(false);

      return { error: 'Something went wrong' };
    } catch (err) {
      console.log('Error on handleSignUp: ', err);
      setAuthUser(null);
      setAuthLoading(false);

      if (err.code) {
        return { error: 'Something went wrong: ' + err.code };
      }
      return { error: 'Something went wrong' };
    }
  };

  const handleLogIn = async (email, password) => {
    console.log('Calling handleLogIn');
    try {
      setAuthLoading(true);
      const auth = getAuth(app);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setAuthLoading(false);

      if (userCredential.user) {
        return { error: false, errorMessage: '' };
      }

      window.location.reload(false);

      return { error: true, errorMessage: 'Something went wrong' };
    } catch (err) {
      console.log('Error on handleLogIn: ', err);
      setAuthUser(null);
      setAuthLoading(false);

      if (err.code) {
        return {
          error: true,
          errorMessage: err.code,
        };
      }
      return { error: true, errorMessage: 'Something went wrong' };
    }
  };

  const handleGoogleSignUp = async () => {
    console.log('Calling handleGoogleSignUp');
    try {
      setAuthLoading(true);
      const provider = new GoogleAuthProvider();
      const auth = getAuth(app);
      const result = await signInWithRedirect(auth, provider);

      setAuthLoading(false);

      if (result.user) {
        return { error: false };
      }

      window.location.reload(false);

      return { error: 'Something went wrong' };
    } catch (err) {
      console.log('Error on handleGoogleSignUp: ', err);
      setAuthUser(null);
      setAuthLoading(false);

      if (err.code) {
        return { error: 'Something went wrong: ' + err.code };
      }
      return { error: 'Something went wrong' };
    }
  };

  const handleGoogleLogIn = async () => {
    console.log('Calling handleGoogleLogIn');
    try {
      setAuthLoading(true);
      const provider = new GoogleAuthProvider();
      const auth = getAuth(app);
      const result = await signInWithRedirect(auth, provider);
      setAuthLoading(false);

      if (result.user) {
        return { error: false };
      }

      window.location.reload(false);

      return { error: 'Something went wrong' };
    } catch (err) {
      console.log('Error on handleGoogleLogIn: ', err);
      setAuthUser(null);

      window.location.reload(false);

      if (err.code) {
        return { error: 'Something went wrong: ' + err.code };
      }
      return { error: 'Something went wrong' };
    }
  };

  const handleSignOut = async () => {
    console.log('Calling handleSignOut');
    try {
      const auth = getAuth(app);
      setAuthLoading(true);
      await signOut(auth);
      setAuthUser(null);

      window.location.reload(false);

      return {};
    } catch (err) {
      console.log('Error on handleSignOut: ', err);
      setAuthLoading(false);
    }
  };

  const handleSubmitApplication = async applicationResponses => {
    console.log('Calling handleSubmitApplication');

    try {
      const auth = getAuth(app);
      setAuthLoading(true);

      await setDoc(
        doc(db, 'agent_application_responses', auth.currentUser.uid),
        {
          uid: applicationResponses.uid,
          stepOne: applicationResponses.stepOne,
          stepTwo: applicationResponses.stepTwo,
          stepThree: applicationResponses.stepThree,
          timeSubmitted: Timestamp.now(),
        }
      );
      console.log('document uploaded');

      const usersRef = doc(db, 'agents', auth.currentUser.uid);
      await updateDoc(usersRef, {
        applicationCompleted: true,
        legalName: {
          firstName: applicationResponses.legalName.firstName,
          lastName: applicationResponses.legalName.lastName,
        },
      });
      console.log('document updated');

      window.location.reload(false);

      return {};
    } catch (err) {
      console.log('Error on handleSubmitApplication: ', err);
      setAuthLoading(false);
    }
  };

  const updateAgentFields = async updatedField => {
    const auth = getAuth(app);
    const docRef = doc(db, 'agents', auth.currentUser.uid);
    await setDoc(docRef, updatedField, {
      merge: true,
    });
    // await getAllAgentsData();
  };

  const getUserApplication = async userId => {
    try {
      const docRef = doc(db, 'agent_application_responses', userId);
      const docSnap = await getDoc(docRef);
      setApplicationData(docSnap.data());
      return docSnap.data();
    } catch (err) {
      console.log(
        '🚀 ~ file: AuthContext.js ~ line 327 ~ getUserApplication ~ err',
        err
      );
    }
  };

  const updateApplicationFields = async updatedField => {
    const auth = getAuth(app);
    const docRef = doc(db, 'agent_application_responses', auth.currentUser.uid);
    await setDoc(docRef, updatedField, {
      merge: true,
    });
  };

  const handleFirebaseAction = async (
    mode,
    actionCode,
    continueUrl,
    lang,
    newPassword = ''
  ) => {
    console.log('Calling handleFirebaseAction');
    try {
      const auth = getAuth(app);

      // Handle the user management action.
      switch (mode) {
        case 'resetPassword':
          // Display reset password handler and UI.
          handleResetPassword(auth, actionCode, continueUrl, lang, newPassword);
          break;
        case 'recoverEmail':
          // Display email recovery handler and UI.
          // handleRecoverEmail(auth, actionCode, lang)
          break;
        case 'verifyEmail':
          // Display email verification handler and UI.
          handleVerifyEmail(auth, actionCode, continueUrl, lang);
          break;
        default:
        // Error: invalid mode.
      }

      navigate('/');
    } catch (err) {
      console.log('Error on handleFirebaseAction: ', err);
      setAuthLoading(false);
    }
  };

  const handleVerifyEmail = async (auth, actionCode, continueUrl, lang) => {
    console.log('Calling handleVerifyEmail');
    setAuthLoading(true);
    applyActionCode(auth, actionCode)
      .then(async resp => {
        const userEmail = auth.currentUser.email;
        toast({
          title: 'Email Verified.',
          description: 'You email has been verified.',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
        await axios({
          method: 'post',
          url: `${process.env.REACT_APP_SERVER_URL}/api/v1/email/welcome_email`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            emailTo: userEmail,
          }),
        });
        console.log('email sent');
        await sleep(1000);
        window.location.reload(false);
      })
      .catch(error => {
        console.log('Error on handleVerifyEmail: ', error);
      });
  };

  const handleResetPassword = async (
    auth,
    actionCode,
    continueUrl,
    lang,
    newPassword
  ) => {
    console.log('Calling handleVerifyEmail');
    setAuthLoading(true);
    confirmPasswordReset(auth, actionCode, newPassword)
      .then(async resp => {
        toast({
          title: 'New Password Set.',
          description: 'You password has been reset.',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
        await sleep(1000);
        window.location.reload(false);
      })
      .catch(error => {
        console.log('Error on handleVerifyEmail: ', error);
      });
  };

  const handleSendPasswordResetEmail = async email => {
    console.log('Calling handleSendPasswordResetEmail');
    try {
      setAuthLoading(true);
      const auth = getAuth(app);
      await sendPasswordResetEmail(auth, email);
      setAuthLoading(false);

      return { error: false, errorMessage: '' };
    } catch (err) {
      console.log('Error on handleSendPasswordResetEmail: ', err);
      setAuthLoading(false);

      return { error: true, errorMessage: err };
    }
  };

  const value = {
    authUser,
    authLoading,
    applicationData,
    setAuthUser,
    setAuthLoading,
    handleSignUp,
    handleLogIn,
    handleGoogleSignUp,
    handleGoogleLogIn,
    handleSignOut,
    handleSubmitApplication,
    handleFirebaseAction,
    handleSendPasswordResetEmail,
    updateApplicationFields,
    updateAgentFields,
    getUserApplication,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
