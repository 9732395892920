import React, { Fragment } from 'react';

// chakra
import {
  Box,
  Container,
  Heading,
  Stack,
  Image,
  Text,
  Button,
  Spinner,
  Center,
} from '@chakra-ui/react';

const Loading = () => {
  return (
    <Fragment>
      <Center w="100%" h="100vh">
        <Spinner size="lg" />
      </Center>
    </Fragment>
  );
};

export default Loading;
