import React, { Fragment } from 'react';

// chakra
import {
  Box,
  Container,
  Heading,
  Stack,
  Image,
  Text,
  Button,
} from '@chakra-ui/react';

const VerifyEmailSection = () => {
  return (
    <Fragment>
      <Container maxW="container.sm">
        <Box
          w="100%"
          px={6}
          py={10}
          bg="white"
          rounded="md"
          align="center"
          textAlign="center"
        >
          <Stack spacing="8" align="center" textAlign="center">
            <Image
              boxSize="100px"
              objectFit="cover"
              src="https://i.imgur.com/sqZQUYR.png"
              alt="emails"
            />
            <Box>
              <Heading as="h3" size="lg" mb="3">
                Verify your email
              </Heading>
              <Text fontSize="md" color="gray.500">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam.
              </Text>
            </Box>
            <Button colorScheme="blue">Resend Email</Button>
          </Stack>
        </Box>
      </Container>
    </Fragment>
  );
};

export default VerifyEmailSection;
